import React from 'react'
import VariantFilter from './VariantFilter'
import {Input} from 'reactstrap'
// import ProductVariantFilter from '../ProductVariantFilter'

const VariantTableRow = (props) => {
    const {
        filter,
        filteredVariants,
        handleFilterVariants,
        attributes,
        selectedVariants,
        setSelectedVariants,
        variants,
    } = props

    const _handleSelectVariant = (id) => {
        const index = selectedVariants.indexOf(id)
        const newSelectedVariants = [...selectedVariants]
        if (index > -1) {
            newSelectedVariants.splice(index, 1)
        } else {
            newSelectedVariants.push(id)
        }
        setSelectedVariants(newSelectedVariants)
    }

    return (
        <tbody>
            <VariantFilter
                filter={filter}
                variants={variants}
                attributes={attributes}
                selectedVariants={selectedVariants}
                filteredVariants={filteredVariants}
                handleFilterVariants={handleFilterVariants}
            />
            {!!filteredVariants.length ? (
                filteredVariants.map((variant, index) => {
                    const {_id, sku, attributes, base_cost, disabled} = variant
                    return (
                        <tr key={`variant_${index}`}>
                            <td className="text-center">
                                <div className="custom-control custom-checkbox">
                                    <Input
                                        id={sku}
                                        type="checkbox"
                                        className="custom-control-input"
                                        checked={selectedVariants.includes(_id)}
                                        onChange={() => _handleSelectVariant(_id)}
                                        disabled={disabled}
                                    />
                                    <label className="custom-control-label" htmlFor={sku}></label>
                                </div>
                            </td>
                            {!!attributes?.length &&
                                attributes.map((attribute) => {
                                    const {type, value_text, value_code} = attribute
                                    return (
                                        <td key={`${type}_${sku}`}>
                                            <label className="d-flex align-items-center mb-0 pt-1">
                                                {type === 'color' && (
                                                    <div
                                                        className="ColorItem me-1"
                                                        style={{
                                                            width: '16px',
                                                            height: '16px',
                                                            borderRadius: '50%',
                                                            background: value_code,
                                                            border: '1px solid #dee2e6',
                                                        }}
                                                    />
                                                )}
                                                {value_text}
                                            </label>
                                        </td>
                                    )
                                })}
                            <td className="text-right">{base_cost ? `$${base_cost}` : '-'}</td>
                        </tr>
                    )
                })
            ) : (
                <tr className="text-center">
                    <td className="text-muted fs-14" colSpan={2 + attributes.length}>
                        No result!
                    </td>
                </tr>
            )}
        </tbody>
    )
}

export default VariantTableRow
