import React from 'react'
import DropdownFilter from './DropdownFilter'

const VariantFilter = (props) => {
    const {variants, attributes, filteredVariants, selectedVariants, handleFilterVariants, filter} = props

    const _onFilterVariant = (option) => {
        const {name, value, type} = option
        const newFilter = {...filter}
        if (type === 'single') {
            const idx = newFilter[name].indexOf(value)
            if (idx > -1) {
                newFilter[name].splice(idx, 1)
            } else {
                newFilter[name].push(value)
            }
        } else {
            newFilter[name] = value
        }
        handleFilterVariants(newFilter)
    }

    return (
        <tr className="ProductVariantsRow">
            <td>
                <span className="fw-500">Filter</span>
            </td>
            {attributes.map((attribute) => {
                const {name} = attribute
                return (
                    <td key={attribute.type}>
                        <DropdownFilter
                            name={name}
                            variants={variants}
                            selectedVariants={selectedVariants}
                            filteredVariants={filteredVariants}
                            attribute={attribute}
                            onChange={_onFilterVariant}
                        />
                    </td>
                )
            })}
            <td></td>
        </tr>
    )
}

export default VariantFilter
