import _ from 'lodash'
import React, {useMemo, useState} from 'react'
import {ReactComponent as Chain} from '../../../assets/icons/link.svg'
import {ReactComponent as Undo} from '../../../assets/icons/red_undo.svg'
import useEditorDesign from '../../../hooks/useEditorDesign'
import {useSelectedVariantContext} from '../context/SelectedVariantContext'
import {actionChangeLinkedVariant} from '../context/SelectedVariantContext/action'
import ProductAttributes from './ProductAttributes'
import AvailableProductVariant from './available-product-variant/AvailableProductVariant'

const ProductVariantContainer = ({className}) => {
    const {
        state: {variants, product, pickerVariants, mappedAttributes},
        dispatch,
    } = useSelectedVariantContext()

    const design = useEditorDesign()
    const [isOpen, setIsOpen] = useState(false)

    const _toggleProductVariant = () => setIsOpen((prev) => !prev)

    const {linked, isDefault} = useMemo(() => {
        const linked = design?.isDefault

        let isManyAttr = false
        for (const key in mappedAttributes) {
            const values = mappedAttributes[key]
            if (values.length > 1) {
                isManyAttr = true
                break
            }
        }

        return {
            linked,
            isDefault: (linked && design.linkedAttrIds?.length <= 1) || !isManyAttr,
        }
    }, [design, mappedAttributes])

    const handleChangeLink = () => {
        if (linked && design.linkedAttrIds.length <= 1) return
        dispatch(actionChangeLinkedVariant(!linked))
    }

    // useEffect(() => {
    //     product && setIsOpen(true)
    // }, [product])

    return (
        <div className={`ListProductLineConfirm ${className}`}>
            <ul className="ContentListProductLine">
                <li className="ItemCatalog">
                    <div className="HeaderItem">
                        <div className="Img">
                            <div className="ImageWrapper">
                                <img src={_.get(product, 'thumbnail_link', '')} alt="Variant thumbnail" />
                            </div>
                        </div>
                        <div className="Title ellipsis-1 w-100">{_.get(product, 'title', '')}</div>
                    </div>

                    <div className="ContentItem d-block">
                        <div className="d-flex align-items-center justify-content-between fs-14">
                            <label className="fw-500 mb-0">Product Variants</label>
                            <div
                                id="add_variant_mockup_v2"
                                className="ButtonAddVariants cursor-pointer text-primary"
                                onClick={_toggleProductVariant}
                            >
                                <span className="variant_edit fw-500" id={'add_variant'}>
                                    Add variants
                                </span>
                            </div>
                        </div>
                        <div className="fs-14">
                            <span className="fw-500 text-primary">
                                {pickerVariants.length} of {variants.length}
                            </span>{' '}
                            <span className="text-muted">variants selected</span>
                        </div>
                        <ProductVariants linked={linked} isDefault={isDefault} onChangeLink={handleChangeLink} />
                    </div>
                </li>
            </ul>
            {isOpen && (
                <AvailableProductVariant
                    isOpen={isOpen}
                    toggle={_toggleProductVariant}
                    attributes={product.attributes}
                />
            )}
        </div>
    )
}

const ProductVariants = ({linked, isDefault, onChangeLink}) => {
    return (
        <div className="ProductVariants">
            <ProductAttributes />
            <div className="SpecificDesign mt-3">
                {linked ? (
                    <div className="fs-14">You’re editing the design for all selected variants</div>
                ) : (
                    <div className="fs-14">You’re editing the specific design</div>
                )}

                {!isDefault && (
                    <span
                        className={`cursor-pointer fs-14 ${linked ? 'text-primary' : 'text-danger'}`}
                        onClick={onChangeLink}
                    >
                        {linked ? (
                            <div className="mt-2 variant_edit" id="mark_specific_design">
                                <Chain width={20} height={20} /> Mark as specific variant design
                            </div>
                        ) : (
                            <div className="mt-2 variant_edit" id="mark_specific_default">
                                <Undo width={20} height={20} /> Revert this specific design to the default design
                            </div>
                        )}
                    </span>
                )}
            </div>
        </div>
    )
}

export default ProductVariantContainer
